@import "../abstracts/_mixins";
@import "../abstracts/_variables";

.works-tab-menu {
  $self: &;
  width: 100%;

  &__tab-buttons {
    @include flexRow(center, center);
    gap: 16px;
  }

  &__tab-button {
    cursor: pointer;
    flex: 1;
    padding: 12px 40px;
    display: inline-block;
    border: 1px solid $main-color;
    font-size: 20px;
    color: $main-color;
    text-align: center;
    transition: all 0.4s ease-in-out;

    @include hover {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }

    @include mediaQuery(sp) {
      padding: 8px;
      font-size: 14px;
    }

    &.active {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }
  }

  &__tab-content {
    width: 100%;
    margin-top: -1px;
    padding: 16px;
    border: 1px solid $main-color;
    position: relative;
    z-index: 2;
  }

  &__tab-menu-list {
    @include flexColumn(flex-start, flex-start);
    gap: 16px;
  }

  &__tab-menu-list-item {
    list-style: circle;
    margin-left: 16px;
    line-height: 150%;

    &--number {
      list-style: decimal;
      margin-left: 24px;
      line-height: 150%;

      &:first-of-type {
        padding-top: 16px;
      }
    }
  }

  &__background-text {
    line-height: 150%;
  }

  &__point-text {
    padding: 0 4px;
    font-size: 32px;
    color: $key-color;
  }
}
