@import "../abstracts/_mixins";
@import "../abstracts/_variables";

.works-detail {
  $self: &;
  width: 100%;
  height: 100%;

  &__page-title {
    display: block;
    margin: 100px 10vw;
    padding-left: 16px;
    @include border;
    @include scriptFont;
    font-size: 100px;

    @include mediaQuery(tab) {
      margin: 100px 16px;
      padding-left: 0;
    }

    @include mediaQuery(sp) {
      margin: 56px 16px;
      font-size: 56px;
    }

    &::before {
      content: "#";
      margin-right: 8px;
    }
  }

  &__section {
    width: 100%;
    margin-top: 100px;
    padding: 0 10vw 100px;
    @include border;

    @include mediaQuery(tab) {
      padding: 0 16px 100px;
    }

    &--kv {
      padding: 0 10vw 100px;
      @include flexColumn(center, center);
      gap: 24px;
      @include border;

      @include mediaQuery(tab) {
        padding: 0 16px 100px;
      }
    }
  }

  &__section-title {
    display: block;
    margin-bottom: 80px;
    padding-left: 16px;
    @include border;
    @include scriptFont;
    font-size: 80px;

    @include mediaQuery(tab) {
      padding-left: 0;
    }

    @include mediaQuery(sp) {
      margin-bottom: 48px;
      font-size: 48px;
    }

    &::before {
      content: "##";
      margin-right: 8px;
    }
  }

  &__works-title-wrapper {
    @include flexColumn(center, center);
    gap: 8px;
  }

  &__product-name {
    @include mediaQuery(sp) {
      font-size: 14px;
    }
  }

  &__works-title {
    font-size: 24px;

    @include mediaQuery(sp) {
      font-size: 16px;
    }
  }

  &__kv {
    max-width: 100%;
    aspect-ratio: 16 / 9;

    img {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    padding: 16px 48px;
    border: 1px solid $main-color;
    font-size: 24px;
    color: $main-color;
    text-align: center;
    transition: all 0.4s ease-in-out;

    @include hover {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }

    @include mediaQuery(tab) {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }
  }
}
