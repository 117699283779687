@import "../abstracts/_mixins";
@import "../abstracts/_variables";

.top-page {
  $self: &;
  width: 100%;
  height: 100%;

  &__section {
    width: 100%;
    margin-top: 100px;
    padding: 0 10vw 100px;
    @include border;

    @include mediaQuery(tab) {
      padding: 0 16px 56px;
    }

    @include mediaQuery(sp) {
      margin-top: 56px;
    }
  }

  &__section-title {
    display: block;
    margin-bottom: 80px;
    padding-left: 16px;
    @include border;
    @include scriptFont;
    font-size: 80px;

    @include mediaQuery(tab) {
      padding-left: 0;
    }

    @include mediaQuery(sp) {
      margin-bottom: 48px;
      font-size: 48px;
    }

    &::before {
      content: "##";
      margin-right: 8px;
    }

    &--page {
      margin-bottom: 100px;
      font-size: 100px;
      @include border;
      @include scriptFont;

      @include mediaQuery(sp) {
        margin-bottom: 56px;
        font-size: 56px;
      }

      &::before {
        content: "#";
        margin-right: 8px;
      }
    }
  }

  &__kv-paragraph-wrapper {
    width: 100%;
    padding-left: 16px;
    @include flexColumn(flex-start, flex-start);
    gap: 20px;

    @include mediaQuery(tab) {
      padding-left: 0;
    }
  }

  &__kv-paragraph {
    line-height: 150%;

    @include mediaQuery(sp) {
      font-size: 14px;
    }
  }

  &__section-flex {
    width: 100%;
    @include flexRow(flex-start, flex-start);
    gap: 48px;

    @include mediaQuery(tab) {
      margin-top: 80px;
      @include flexColumn(flex-start, flex-start);
    }

    @include mediaQuery(sp) {
      margin-top: 56px;
    }
  }

  &__selfie {
    flex: 1;
    max-width: 100%;

    @include mediaQuery(tab) {
      flex: inherit;
    }

    img {
      width: 100%;
    }
  }

  &__text-contents-wrapper {
    flex: 1;
    @include flexColumn(flex-start, flex-start);
    gap: 48px;

    @include mediaQuery(tab) {
      flex: inherit;
      width: 100%;
      flex-direction: row;
    }

    @include mediaQuery(sp) {
      flex-direction: column;
    }

    &--no-flex {
      flex: inherit;
      @include flexColumn(flex-start, flex-start);
      gap: 48px;
    }
  }

  &__text-contents {
    width: 100%;
    @include flexColumn(flex-start, flex-start);
    gap: 24px;
  }

  &__sub-title {
    font-size: 32px;
    @include flexRow(flex-start, center);
    gap: 8px;

    @include mediaQuery(tab) {
      font-size: 24px;
    }

    &::before {
      content: "";
      display: block;
      width: 2px;
      height: 48px;
      background-color: $main-color;

      @include mediaQuery(tab) {
        height: 32px;
      }
    }
  }

  &__label-and-text-wrapper {
    width: 100%;
    @include flexColumn(flex-start, flex-start);
    gap: 16px;
  }

  &__label-and-text {
    width: 100%;
    @include flexRow(flex-start, flex-start);
    gap: 8px;
  }

  &__label {
    display: block;
    min-width: 102px;
    padding: 8px 16px;
    background-color: $main-color;
    color: $base-color;
    text-align: center;
    white-space: nowrap;
  }

  &__label-text {
    line-height: 32px;
  }

  &__text-and-icon-wrapper {
    @include flexColumn(flex-start, flex-start);
    gap: 12px;
  }

  &__text-and-icon {
    @include flexColumn(flex-start, flex-start);
    gap: 8px;
  }

  &__icon-wrapper {
    @include flexRow(flex-start, flex-start);
    gap: 8px;
    flex-wrap: wrap;

    svg {
      width: 48px;
      height: 48px;
    }
  }
}
