@import "../abstracts/_mixins";
@import "../abstracts/_variables";

.works-list {
  $self: &;
  width: 100%;
  padding-bottom: 48px;
  @include border;
  @include flexColumn(center, center);
  gap: 24px;

  @include mediaQuery(sp) {
    padding-bottom: 24px;
  }

  & + & {
    margin-top: 48px;

    @include mediaQuery(sp) {
      margin-top: 24px;
    }
  }

  &__title-wrap {
    @include flexColumn(center, center);
    gap: 8px;
  }

  &__product-name {
    @include mediaQuery(sp) {
      font-size: 14px;
    }
  }

  &__project-title {
    font-size: 24px;

    @include mediaQuery(sp) {
      font-size: 16px;
    }
  }

  &__image {
    max-width: 100%;
    aspect-ratio: 16 / 9;

    img {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    padding: 16px 48px;
    border: 1px solid $main-color;
    font-size: 24px;
    color: $main-color;
    text-align: center;
    transition: all 0.4s ease-in-out;

    @include hover {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }

    @include mediaQuery(tab) {
      background-color: $key-color;
      border-color: $key-color;
      color: $base-color;
    }
  }
}
