@import "../abstracts/_mixins";
@import "../abstracts/_variables";

.header {
  width: 100%;
  padding: 32px 10vw;
  background-color: $base-color;
  @include border;
  @include flexRow(space-between, center);
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;

  @include mediaQuery(tab) {
    padding: 16px;
  }

  &__site-name {
    @include subFont;
    font-size: 24px;
    line-height: 150%;

    @include mediaQuery(sp) {
      font-size: 20px;
    }
  }

  &__burger-menu {
    width: 48px;
    height: 48px;
    padding: 8px;
    @include flexColumn(center, center);
    gap: 8px;
    cursor: pointer;

    &.open {
      .header__burger-icon-border {
        transform: translateY(4px) rotate(150deg);

        &:nth-child(2) {
          transform: translateY(-4px) rotate(-150deg);
        }
      }
    }
  }

  &__burger-icon-border {
    display: block;
    width: 32px;
    height: 1px;
    background-color: $main-color;
    transform-origin: center;
    transition: all 0.4s;
  }

  &__burger-nav {
    width: max-content;
    height: fit-content;
    border-left: 1px solid $border-color;
    box-shadow: -1px 0 0 $border-shadow;
    @include flexColumn(center, flex-start);
    position: fixed;
    top: 137px;
    left: 100vw;
    z-index: 998;
    transition: all 0.8s;

    @include mediaQuery(tab) {
      top: 105px;
    }

    @include mediaQuery(sp) {
      top: 93px;
    }

    &.open {
      transform: translateX(-100%);
    }
  }

  &__nav-flex {
    width: 100%;
    @include flexColumn(flex-start, flex-start);
  }

  &__nav-flex-item {
    display: inline-block;
    width: 100%;
    height: max-content;
    background-color: $base-color;
    @include border;
    font-size: 20px;

    @include mediaQuery(sp) {
      font-size: 14px;
    }
  }

  &__nav-link {
    @include flexColumn(flex-start, flex-start);
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 16px calc(10vw + 8px) 16px 20px;
    transition: all 0.4s;

    @include mediaQuery(tab) {
      padding: 14px 24px 14px 14px;
    }

    &.active {
      color: $key-color;
    }

    &::before {
      content: attr(data-product-name);
      font-size: 14px;

      @include mediaQuery(sp) {
        font-size: 12px;
      }
    }
  }
}
