@import "../abstracts/_variables";

// font
@mixin scriptFont {
  font-family: "Sacramento", cursive;
  letter-spacing: 0;
}

@mixin subFont {
  font-family: "Roboto Slab", serif;
}

// media query
@mixin mediaQuery($device-type) {
  @if map-has-key($device, $device-type) {
    @media #{map-get($device, $device-type)} {
      @content;
    }
  } @else {
    @warn "No media query found for '#{$device-type}'.";
  }
}

// hover
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

$device: (
  sp: "screen and (max-width: 600px)",
  tab: "screen and (max-width: 960px)",
  pc: "screen and (min-width: 960px)",
  sidebar: "screen and (max-width: 1390px)",
  monitor: "screen and (max-width: 1920px)",
);

// mixins
@mixin flexRow($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin flexColumn($justify, $align) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

@mixin border {
  border-bottom: 1px solid $border-color;
  box-shadow: 0 1px 0 $border-shadow;
}
