@import "../abstracts/_variables";
@import "../abstracts/_mixins";

* {
  box-sizing: border-box;
  background-color: $base-color;
  font-family: "Noto Serif JP", serif;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.08em;
  color: $main-color;
}

.copyright {
  display: block;
  width: 100%;
  padding: 32px 0;
  font-size: 12px;
  text-align: center;
}
